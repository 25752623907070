.waving-line-container {
    position: absolute; /* Position it absolutely within OtherComponent */
    top: 400px;
    left: 0;
    width: 100%;
    height: 500px;
    overflow: hidden;
  }

.waving-line-svg {
    position: absolute;
    bottom: 0px;
    width: 100%;
    
   
    fill: none;
    stroke: lightBlue;
    strokeWidth: 1;
    animation: wavingAnimation 5s ease-in-out infinite alternate;
  }
  
  @keyframes wavingAnimation {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, -20px);
    }
  }
  