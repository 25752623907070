.jellyfish-animation-container {
  position: relative;
  width: 140px; 
  animation: pulseAndRotate 6s ease-in-out infinite; 
}

.animated-jellyfish {
  width: 100%;
  height: 140px;
}

@keyframes pulseAndRotate {
  0%, 100% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(180deg);
  }
}
