.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes animate-snake-container {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-snake-container {
  animation: animate-snake-container 0.5s ease-in-out;
}

.animate-snake-item {
  animation: animate-snake 1s linear forwards;
}

.animate-snake-item-1 {
  animation-delay: 0.1s;
}

.animate-snake-item-2 {
  animation-delay: 0.2s;
}

.animate-snake-item-3 {
  animation-delay: 0.3s;
}

.animate-snake-item-4 {
  animation-delay: 0.4s;
}